// import axios from 'axios'

export const scripts = Object.create(null)

// const kinescopeKey = process.env.VUE_APP_KINESCOPE_LIVE_KEY
// const kinescopeBaseURL = 'https://api.kinescope.io/v1'
//
// const ksRequest = axios.create({
//   kinescopeBaseURL,
//   headers: {
//     Accept: 'application/json'
//   }
// })

// ;[ksRequest].forEach(instance => {
//   instance.interceptors.request.use(
//     config => {
//       config.headers.Authorization = `Bearer ${kinescopeKey}`
//       return config
//     },
//     error => Promise.reject(error)
//   )
// })

// export function createKSEvent (eventName) {
//   ksRequest.post('/live/events', {
//     name: eventName,
//     type: 'one-time',
//     auto_start: true,
//     save_stream: true,
//     dvr: false
//   })
// }

export function hashCode (str) {
  let hash = 0
  for (let i = 0, len = str.length; i < len; i++) {
    const chr = str.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0
  }
  return Math.abs(hash)
}

function load (url) {
  return new Promise((resolve, reject) => {
    const script = window.document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = url
    script.onload = resolve
    script.onerror = reject
    window.document.body.appendChild(script)
  })
}

function delay (time) {
  return new Promise((resolve) => {
    setTimeout(resolve, time)
  })
}

export async function loadScript (url, name) {
  if (window[name] !== undefined) {
    return window[name]
  } else if (scripts[name] !== undefined) {
    return scripts[name]
  } else {
    scripts[name] = load(url).then(async () => {
      await delay(0)
      return window[name]
    })
    scripts[name].then(() => {
      delete scripts[name]
    })
    return scripts[name]
  }
}

export const newId = () => {
  return (Date.now().toString(36) + Math.random().toString(36).substr(2, 5))// .toUpperCase()
}

export const VJitsi = {
  props: {
    admin: {
      type: Boolean
    },
    room: {
      type: String,
      required: true
    },
    nickname: {
      type: String
    },
    avatar: {
      type: String
    },
    password: {
      type: String
    },
    logo: {
      type: String
    },
    color: {
      type: String,
      default: '#1d1e22'
    }
  },
  methods: {
    async createRoom () {
      // https://jitsi.github.io/handbook/docs/dev-guide/dev-guide-iframe
      // https://github.com/jitsi/jitsi-meet/blob/master/interface_config.js
      // https://github.com/findmate/cordova-plugin-jitsi-meet
      const API = await loadScript('https://meet.jit.si/external_api.js', 'JitsiMeetExternalAPI')
      const options = {
        // tokenAuthUrl: 'https://jvillanueva-d1bc55af.localhost.run/station/test/jwt/{room}/{roleUpgrade}',
        jwt: '',
        roomName: this.room || ('vjitsi_' + newId()),
        width: '100%',
        height: '100%',
        parentNode: this.$el,
        userInfo: {
          // email: 'email@jitsiexamplemail.com'
        },
        configOverwrite: {
          // tokenAuthUrl: 'https://jvillanueva-d1bc55af.localhost.run/station/test/jwt/{room}/{roleUpgrade}',
          enableUserRolesBasedOnToken: true, // role
          defaultLanguage: 'es',
          disableDeepLinking: true,
          prejoinPageEnabled: false,
          localRecording: {
            enabled: true,
            format: 'flac'
          }
        },
        interfaceConfigOverwrite: {
          HIDE_INVITE_MORE_HEADER: true,
          DEFAULT_LOGO_URL: 'https://i.imgur.com/scrcyJ3.png',
          // SHOW_WATERMARK_FOR_GUESTS: true,
          // SHOW_BRAND_WATERMARK: false,
          // JITSI_WATERMARK_LINK: '',
          // SHOW_JITSI_WATERMARK: false,
          DEFAULT_BACKGROUND: this.color,
          TOOLBAR_BUTTONS: [
            'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
            'fodeviceselection', 'hangup', 'chat', 'recording',
            'livestreaming', 'etherpad', 'sharedvideo', 'settings', 'raisehand',
            'videoquality', 'filmstrip', 'invite', 'feedback', 'stats', 'shortcuts',
            'tileview', 'videobackgroundblur', 'download', 'help', 'mute-everyone',
            'e2ee', 'localrecording'
          ],
          SHOW_CHROME_EXTENSION_BANNER: false,
          SETTINGS_SECTIONS: ['devices', 'moderator'],
          MOBILE_APP_PROMO: false,
          LANG_DETECTION: true,
          DEFAULT_REMOTE_DISPLAY_NAME: 'Nuevo usuario',
          DEFAULT_LOCAL_DISPLAY_NAME: 'Yo'
        },
        onload: (event) => {
          if (this.admin === true) {
            this._api.addEventListener('participantRoleChanged', (event) => {
              if (event.role === 'moderator') {
                this._api.executeCommand('password', this.password)
              }
            })
          } else {
            this._api.on('passwordRequired', () => {
              this._api.executeCommand('password', this.password)
            })
          }

          this.$emit('load', event)
        }
      }
      this._api = new API('meet.jit.si', options)
      this._api.executeCommand('displayName', this.nickname)
      this._api.executeCommand('avatarUrl', this.avatar)

      this._api.addEventListener('videoConferenceJoined', this.$emit.bind(this, 'videoConferenceJoined')) // {roomName,id,displayName,avatarURL}
      this._api.addEventListener('participantLeft', this.$emit.bind(this, 'participantLeft')) // {id}
      this._api.addEventListener('participantJoined', this.$emit.bind(this, 'participantJoined')) // {id, displayName}
      this._api.addEventListener('incomingMessage', this.$emit.bind(this, 'incomingMessage')) // {from, message, nick}
    }
  },
  watch: {
    nickname (value) {
      this._api.executeCommand('displayName', value)
    },
    avatar (value) {
      this._api.executeCommand('avatarUrl', value)
    }
  },
  mounted () {
    this.createRoom()
  },
  render (h) {
    return h('div')
  }
}
